const $ = selector => {
    const elements = document.querySelectorAll(selector)
    return elements.length === 1 ? elements[0] : elements
}
const DEFAULTCOLOR = document.body.style.backgroundColor

const setBg = color => {
    document.body.style.backgroundColor = color
}

const resetBg = setBg.bind(null, DEFAULTCOLOR)

$('a[data-color]').forEach(link => {
    const color = link.attributes['data-color'].value
    const boundBg = setBg.bind(null, color)
    const eventMap = Object.entries({
        mouseenter: boundBg,
        touchstart: boundBg,
        mouseleave: resetBg,
        touchend: resetBg
    })

    for (let [name, handler] of eventMap) {
        link.addEventListener(name, handler)
    }
})